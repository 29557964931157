import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b66115d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "activity-name" }
const _hoisted_3 = { class: "activity-span" }
const _hoisted_4 = { class: "activity-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goForward && _ctx.goForward(...args)))
      }, "<"),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
      }, ">")
    ]),
    _createElementVNode("span", null, _toDisplayString(Object.keys(_ctx.groupedActivities)[_ctx.index]), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedActivities[Object.keys(_ctx.groupedActivities)[_ctx.index]], (activity) => {
      return (_openBlock(), _createElementBlock("div", {
        key: activity.id,
        class: "activity-list"
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(activity.activity), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(activity.start) + "-" + _toDisplayString(activity.finish), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(activity.timeTook), 1)
      ]))
    }), 128))
  ], 64))
}