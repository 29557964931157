
import { defineComponent } from "vue"

export default defineComponent({
  name: `Header`,
  components: {},
  data: () => ({
    activity: ``,
  }),
  props: [`start`, `submitActivity`, `startDay`],
  methods: {
    onSubmit() {
      this.$emit(`submitActivity`, this.activity)
      this.activity = ``
    },
    startTimer() {
      this.startDay()
    },
  },
})
