
import { defineComponent } from 'vue'
import Home from './components/PageHome.vue'

export default defineComponent({
  name: `App`,
  components: {
    Home
  }
})
