import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ta_header = _resolveComponent("ta-header")!
  const _component_activity_list = _resolveComponent("activity-list")!
  const _component_version = _resolveComponent("version")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ta_header, {
      start: _ctx.start,
      onSubmitActivity: _ctx.submitActivity,
      startDay: _ctx.startDay
    }, null, 8, ["start", "onSubmitActivity", "startDay"]),
    _createVNode(_component_activity_list, {
      groupedActivities: _ctx.groupedActivities,
      goBack: _ctx.goBackADay,
      goForward: _ctx.goForwardADay
    }, null, 8, ["groupedActivities", "goBack", "goForward"]),
    _createVNode(_component_version)
  ], 64))
}