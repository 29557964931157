
import { defineComponent } from 'vue'

export default defineComponent({
  name: `ActivityList`,
  components: {},
  props: [`groupedActivities`, `goBack`, `goForward`],
  data() {
    return {
      index: 0,
    }
  },
})
